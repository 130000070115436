export default [
  //新客转化 [1,2,3]
  {
    templateId:1,
    title:'访问未支付转化',
    description:'近7天浏览页面次数超过5次，但是没有支付成功过的新客户',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "新客-访问未支付人群转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 259,
          "Type": 100,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
          {
            "AutoMarketingTaskConfigId": 259,
            "AutoMarketingTaskConfigDetailId": 151,
            "RelationId": 0,
            "RelationValue": "",
            "PagePath": "",
            "PageName": "",
            "ProductImgUrlComplete": "",
            "Id": 1153,
            "Delstatus": false,
            "AddTime": "2021-12-01 09:43:26",
            "UpdateTime": "2021-12-01 09:43:26",
            "MallId": 92
          }],
          "Id": 151,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:43:26",
          "UpdateTime": "2021-12-01 09:43:26",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 259,
          "Type": 300,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 1,
          "Num": 5,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
          {
            "AutoMarketingTaskConfigId": 259,
            "AutoMarketingTaskConfigDetailId": 152,
            "RelationId": 0,
            "RelationValue": "",
            "PagePath": "",
            "PageName": "",
            "ProductImgUrlComplete": "",
            "Id": 1154,
            "Delstatus": false,
            "AddTime": "2021-12-01 09:43:26",
            "UpdateTime": "2021-12-01 09:43:26",
            "MallId": 92
          }],
          "Id": 152,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:43:26",
          "UpdateTime": "2021-12-01 09:43:26",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 259,
        "PeriodType": 1,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 43,
        "Delstatus": false,
        "AddTime": "2021-12-01 09:43:26",
        "UpdateTime": "2021-12-01 09:43:26",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 259,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 221,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:43:26",
          "UpdateTime": "2021-12-01 09:56:47",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 259,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 354,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:43:26",
          "UpdateTime": "2021-12-01 09:56:47",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 259,
      "Delstatus": false,
      "AddTime": "2021-12-01 09:43:26",
      "UpdateTime": "2021-12-01 09:56:47",
      "MallId": 92
    },
  },
  {
    templateId:2,
    title:'下单未支付转化',
    description:'近7天有下单，但没有成功付款的新客户',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "新客-下单未支付人群转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 260,
          "Type": 100,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 260,
              "AutoMarketingTaskConfigDetailId": 153,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1155,
              "Delstatus": false,
              "AddTime": "2021-12-01 09:53:29",
              "UpdateTime": "2021-12-01 09:53:29",
              "MallId": 92
            }
          ],
          "Id": 153,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:53:29",
          "UpdateTime": "2021-12-01 09:53:29",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 260,
          "Type": 8,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 2,
          "Num": 1,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 154,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:53:29",
          "UpdateTime": "2021-12-01 09:53:29",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 260,
          "Type": 10,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 5,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 155,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:53:29",
          "UpdateTime": "2021-12-01 09:53:29",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 260,
        "PeriodType": 1,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 44,
        "Delstatus": false,
        "AddTime": "2021-12-01 09:53:29",
        "UpdateTime": "2021-12-01 09:53:29",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 260,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 222,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:53:29",
          "UpdateTime": "2021-12-01 09:56:37",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 260,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 355,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:53:29",
          "UpdateTime": "2021-12-01 09:56:37",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 260,
      "Delstatus": false,
      "AddTime": "2021-12-01 09:53:29",
      "UpdateTime": "2021-12-01 09:56:37",
      "MallId": 92
    },
  },
  {
    templateId:3,
    title:'即将流失新客',
    description:'近30天引入的新客，近7天没有再访问过商城',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "即将流失新客运营",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 268,
          "Type": 100,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 268,
              "AutoMarketingTaskConfigDetailId": 156,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1156,
              "Delstatus": false,
              "AddTime": "2021-12-01 09:56:24",
              "UpdateTime": "2021-12-01 09:56:24",
              "MallId": 92
            }
          ],
          "Id": 156,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:56:24",
          "UpdateTime": "2021-12-01 09:56:24",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 268,
          "Type": 4,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 157,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:56:24",
          "UpdateTime": "2021-12-01 09:56:24",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 268,
          "Type": 300,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 5,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 268,
              "AutoMarketingTaskConfigDetailId": 158,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1157,
              "Delstatus": false,
              "AddTime": "2021-12-01 09:56:24",
              "UpdateTime": "2021-12-01 09:56:24",
              "MallId": 92
            }
          ],
          "Id": 158,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:56:24",
          "UpdateTime": "2021-12-01 09:56:24",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 268,
        "PeriodType": 2,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 50,
        "Delstatus": false,
        "AddTime": "2021-12-01 09:56:24",
        "UpdateTime": "2021-12-01 09:56:24",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 268,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 223,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:56:24",
          "UpdateTime": "2021-12-01 09:56:24",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 268,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 363,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:56:24",
          "UpdateTime": "2021-12-01 09:56:24",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 268,
      "Delstatus": false,
      "AddTime": "2021-12-01 09:56:24",
      "UpdateTime": "2021-12-01 09:56:24",
      "MallId": 92
    },
  },
  //老客促活 [4,5,6,7]
  {
    templateId:4,
    title:'即将流失老客',
    description:'近30天未再下单的老客户',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "即将流失老客人群运营",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 269,
          "Type": 100,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 269,
              "AutoMarketingTaskConfigDetailId": 159,
              "RelationId": 1,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1158,
              "Delstatus": false,
              "AddTime": "2021-12-01 09:58:30",
              "UpdateTime": "2021-12-01 09:58:30",
              "MallId": 92
            }
          ],
          "Id": 159,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:58:30",
          "UpdateTime": "2021-12-01 09:58:30",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 269,
          "Type": 8,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 5,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 160,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:58:30",
          "UpdateTime": "2021-12-01 09:58:30",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 269,
        "PeriodType": 2,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 51,
        "Delstatus": false,
        "AddTime": "2021-12-01 09:58:30",
        "UpdateTime": "2021-12-01 09:58:30",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 269,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 224,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:58:30",
          "UpdateTime": "2021-12-01 09:58:30",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 269,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 364,
          "Delstatus": false,
          "AddTime": "2021-12-01 09:58:30",
          "UpdateTime": "2021-12-01 09:58:30",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 269,
      "Delstatus": false,
      "AddTime": "2021-12-01 09:58:30",
      "UpdateTime": "2021-12-01 09:58:30",
      "MallId": 92
    },
  },
  {
    templateId:5,
    title:'访问未支付转化',
    description:'近30天有浏览店铺，但未进行消费的老客',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "老客-访问未支付转化",
      "State": 2,
      "StartTime": "2021-12-04 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 378,
          "Type": 100,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 378,
              "AutoMarketingTaskConfigDetailId": 266,
              "RelationId": 1,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1251,
              "Delstatus": false,
              "AddTime": "2021-12-04 17:38:11",
              "UpdateTime": "2021-12-04 17:38:11",
              "MallId": 92
            }
          ],
          "Id": 266,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:38:11",
          "UpdateTime": "2021-12-04 17:38:11",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 378,
          "Type": 300,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 2,
          "Num": 1,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 378,
              "AutoMarketingTaskConfigDetailId": 267,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1252,
              "Delstatus": false,
              "AddTime": "2021-12-04 17:38:11",
              "UpdateTime": "2021-12-04 17:38:11",
              "MallId": 92
            }
          ],
          "Id": 267,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:38:11",
          "UpdateTime": "2021-12-04 17:38:11",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 378,
          "Type": 10,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 5,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 268,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:38:11",
          "UpdateTime": "2021-12-04 17:38:11",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 378,
        "PeriodType": 2,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 86,
        "Delstatus": false,
        "AddTime": "2021-12-04 17:38:11",
        "UpdateTime": "2021-12-04 17:38:11",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 378,
          "Type": 1,
          "RelationId": 578,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "优惠券-限制",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 1.23,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减1.23元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 38,
            "AllCount": 100,
            "LeaveCount": 62,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 340,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:38:11",
          "UpdateTime": "2021-12-04 17:38:11",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 378,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 484,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:38:11",
          "UpdateTime": "2021-12-04 17:38:11",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 378,
      "Delstatus": false,
      "AddTime": "2021-12-04 17:38:11",
      "UpdateTime": "2021-12-04 17:38:22",
      "MallId": 92
    }
  },
  {
    templateId:6,
    title:'下单未支付转化',
    description:'近7天有下单行为，但没有成功付款的老客户',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "老客-下单未支付转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 273,
          "Type": 100,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 273,
              "AutoMarketingTaskConfigDetailId": 164,
              "RelationId": 1,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1161,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:01:54",
              "UpdateTime": "2021-12-01 10:01:54",
              "MallId": 92
            }
          ],
          "Id": 164,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:01:54",
          "UpdateTime": "2021-12-01 10:01:54",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 273,
          "Type": 8,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 2,
          "Num": 1,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 165,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:01:54",
          "UpdateTime": "2021-12-01 10:01:54",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 273,
          "Type": 10,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 5,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 166,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:01:54",
          "UpdateTime": "2021-12-01 10:01:54",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 273,
        "PeriodType": 1,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 53,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:01:54",
        "UpdateTime": "2021-12-01 10:01:54",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 273,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 226,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:01:54",
          "UpdateTime": "2021-12-01 10:01:54",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 273,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 368,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:01:54",
          "UpdateTime": "2021-12-01 10:01:54",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 273,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:01:54",
      "UpdateTime": "2021-12-01 10:01:54",
      "MallId": 92
    },
  },
  {
    templateId:7,
    title:'重点发展老客',
    description:'近30天有过支付，并且订单数都高于商城平均值，笔单价高于商城客单价的老客户',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "重点发展老客运营",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 276,
          "Type": 100,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 276,
              "AutoMarketingTaskConfigDetailId": 167,
              "RelationId": 1,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1162,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:03:27",
              "UpdateTime": "2021-12-01 10:03:27",
              "MallId": 92
            }
          ],
          "Id": 167,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:03:27",
          "UpdateTime": "2021-12-01 10:03:27",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 276,
          "Type": 7,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 2,
          "Num": 500,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 168,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:03:27",
          "UpdateTime": "2021-12-01 10:03:27",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 276,
          "Type": 10,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 2,
          "Num": 500,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 169,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:03:27",
          "UpdateTime": "2021-12-01 10:03:27",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 276,
        "PeriodType": 2,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 54,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:03:27",
        "UpdateTime": "2021-12-01 10:03:27",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 276,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 227,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:03:27",
          "UpdateTime": "2021-12-01 10:03:27",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 276,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 371,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:03:27",
          "UpdateTime": "2021-12-01 10:03:27",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 276,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:03:27",
      "UpdateTime": "2021-12-01 10:03:27",
      "MallId": 92
    },
  },
  //客户忠诚与关怀 [8,9,10,11,12,13]
  {
    templateId:8,
    title:'会员日营销',
    description:'设置每月会员日，并对商城现有会员进行关怀营销',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "会员日营销",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 2,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": true,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 277,
          "Type": 2,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 277,
              "AutoMarketingTaskConfigDetailId": 170,
              "RelationId": 34,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1163,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:04:33",
              "UpdateTime": "2021-12-01 10:04:33",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 277,
              "AutoMarketingTaskConfigDetailId": 170,
              "RelationId": 35,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1164,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:04:33",
              "UpdateTime": "2021-12-01 10:04:33",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 277,
              "AutoMarketingTaskConfigDetailId": 170,
              "RelationId": 36,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1165,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:04:33",
              "UpdateTime": "2021-12-01 10:04:33",
              "MallId": 92
            }
          ],
          "Id": 170,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:04:33",
          "UpdateTime": "2021-12-01 10:04:33",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 277,
        "PeriodType": 2,
        "PeriodNum": 8,
        "PeriodTimeHour": 10,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 55,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:04:33",
        "UpdateTime": "2021-12-01 10:04:33",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 277,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 228,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:04:33",
          "UpdateTime": "2021-12-01 10:04:33",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 277,
          "Type": 2,
          "RelationId": 2174,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 0,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减0元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 0,
            "AllCount": 0,
            "LeaveCount": 0,
            "IsOpen": false,
            "State": "已失效"
          },
          "WheelSurfInfo": {
            "Name": "抽奖回收优惠券",
            "StartTime": "2021-11-29 00:00:00",
            "EndTime": "2023-11-29 23:59:59"
          },
          "Id": 229,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:04:33",
          "UpdateTime": "2021-12-01 10:04:33",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 277,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 372,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:04:33",
          "UpdateTime": "2021-12-01 10:04:33",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 277,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:04:33",
      "UpdateTime": "2021-12-01 10:04:33",
      "MallId": 92
    },
  },
  {
    templateId:9,
    title:'待转化会员',
    description:'已经达到最低级别会员的成长值门槛，但是还没有开通会员身份',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "待转化会员运营",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 288,
          "Type": 2,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 288,
              "AutoMarketingTaskConfigDetailId": 175,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1170,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:13:23",
              "UpdateTime": "2021-12-01 10:13:23",
              "MallId": 92
            }
          ],
          "Id": 175,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:13:23",
          "UpdateTime": "2021-12-01 10:13:23",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 288,
          "Type": 101,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 2,
          "Num": 500,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 176,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:13:23",
          "UpdateTime": "2021-12-01 10:13:23",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 288,
        "PeriodType": 2,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 58,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:13:23",
        "UpdateTime": "2021-12-01 10:13:23",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 288,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里添加成为会员的页面，客户点击短信里的链接即可打开小程序，激活会员身份https://wxaurl.cn/ZGFvjl28MJq",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 384,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:13:23",
          "UpdateTime": "2021-12-01 10:13:23",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 288,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:13:23",
      "UpdateTime": "2021-12-01 10:13:23",
      "MallId": 92
    },
  },
  {
    templateId:10,
    title:'活跃会员关怀',
    description:'针对上个月在商城内消费的会员定期关怀问候',
    tag: '周期循环',
	  showCount: true,
    data: {
      "Name": "活跃会员关怀",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 285,
          "Type": 2,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 285,
              "AutoMarketingTaskConfigDetailId": 173,
              "RelationId": 34,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1167,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:10:30",
              "UpdateTime": "2021-12-01 10:10:30",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 285,
              "AutoMarketingTaskConfigDetailId": 173,
              "RelationId": 35,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1168,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:10:30",
              "UpdateTime": "2021-12-01 10:10:30",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 285,
              "AutoMarketingTaskConfigDetailId": 173,
              "RelationId": 36,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1169,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:10:30",
              "UpdateTime": "2021-12-01 10:10:30",
              "MallId": 92
            }
          ],
          "Id": 173,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:10:30",
          "UpdateTime": "2021-12-01 10:10:30",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 285,
          "Type": 10,
          "TimeType": 1,
          "DynamicTimeType": 6,
          "ConditionType": 2,
          "Num": 1,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 174,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:10:30",
          "UpdateTime": "2021-12-01 10:10:30",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 285,
        "PeriodType": 2,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 57,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:10:30",
        "UpdateTime": "2021-12-01 10:10:30",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 285,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 230,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:10:30",
          "UpdateTime": "2021-12-01 10:18:35",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 285,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 381,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:10:30",
          "UpdateTime": "2021-12-01 10:18:35",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 285,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:10:30",
      "UpdateTime": "2021-12-01 10:18:35",
      "MallId": 92
    },
  },
  {
    templateId:11,
    title:'新增会员维系',
    description:'近30天成为商城的会员，对其进行关怀问候',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "新增会员维系",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 289,
          "Type": 2,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 289,
              "AutoMarketingTaskConfigDetailId": 177,
              "RelationId": 34,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1171,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:15:06",
              "UpdateTime": "2021-12-01 10:15:06",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 289,
              "AutoMarketingTaskConfigDetailId": 177,
              "RelationId": 35,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1172,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:15:06",
              "UpdateTime": "2021-12-01 10:15:06",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 289,
              "AutoMarketingTaskConfigDetailId": 177,
              "RelationId": 36,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1173,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:15:06",
              "UpdateTime": "2021-12-01 10:15:06",
              "MallId": 92
            }
          ],
          "Id": 177,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:15:06",
          "UpdateTime": "2021-12-01 10:15:06",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 289,
          "Type": 5,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 178,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:15:06",
          "UpdateTime": "2021-12-01 10:15:06",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 289,
        "PeriodType": 2,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 59,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:15:06",
        "UpdateTime": "2021-12-01 10:15:06",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 289,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 231,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:15:06",
          "UpdateTime": "2021-12-01 10:15:06",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 289,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接回复TD退订",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 385,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:15:06",
          "UpdateTime": "2021-12-01 10:15:06",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 289,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:15:06",
      "UpdateTime": "2021-12-01 10:15:06",
      "MallId": 92
    },
  },
  {
    templateId:12,
    title:'签到用户关怀',
    description:'当时签到成功的用户，在签到后24小时推送内容',
    tag: '行为触发',
	  showCount: true,
    data:{
      "Name": "签到用户关怀",
      "State": 2,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2021-11-25 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 1,
      "PushRuleType": 3,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [],
      "AutoMarketingTaskConfigPushRuleEvent": {
        "AutoMarketingTaskConfigId": 290,
        "TimeType": 0,
        "Type": 5,
        "ConditionType": 1,
        "Num": 0,
        "MaxNum": 0,
        "ProductRangeType": 0,
        "PageRangeType": 0,
        "PushDelayDay": 1,
        "AutoMarketingTaskConfigPushRuleEventRelationIdList": [],
        "Id": 96,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:18:48",
        "UpdateTime": "2021-12-01 10:18:48",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 290,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 386,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:18:48",
          "UpdateTime": "2021-12-01 10:18:48",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 290,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:18:48",
      "UpdateTime": "2021-12-01 15:46:45",
      "MallId": 92
    },
  },
  {
    templateId:13,
    title:'活跃用户关怀',
    description:'近7天浏览商城超出10次的客户，对其推送内容',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "活跃用户关怀",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 291,
          "Type": 300,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 2,
          "Num": 10,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 291,
              "AutoMarketingTaskConfigDetailId": 179,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1174,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:20:26",
              "UpdateTime": "2021-12-01 10:20:26",
              "MallId": 92
            }
          ],
          "Id": 179,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:20:26",
          "UpdateTime": "2021-12-01 10:20:26",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 291,
        "PeriodType": 1,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 60,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:20:26",
        "UpdateTime": "2021-12-01 10:20:26",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 291,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接\n",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 387,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:20:26",
          "UpdateTime": "2021-12-01 10:20:26",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 291,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:20:26",
      "UpdateTime": "2021-12-01 10:20:26",
      "MallId": 92
    },
  },
  //客户社交层级 [14,15,16,17,18]
  {
    templateId:14,
    title:'潜力推手转化',
    description:'近7天分享页面次数超过5次的客户，转化成为推手',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "潜力推手转化",
      "State": 2,
      "StartTime": "2021-12-04 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 379,
          "Type": 3,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 379,
              "AutoMarketingTaskConfigDetailId": 269,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1253,
              "Delstatus": false,
              "AddTime": "2021-12-04 17:39:34",
              "UpdateTime": "2021-12-04 17:39:34",
              "MallId": 92
            }
          ],
          "Id": 269,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:39:34",
          "UpdateTime": "2021-12-04 17:39:34",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 379,
          "Type": 302,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 2,
          "Num": 5,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 379,
              "AutoMarketingTaskConfigDetailId": 270,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1254,
              "Delstatus": false,
              "AddTime": "2021-12-04 17:39:34",
              "UpdateTime": "2021-12-04 17:39:34",
              "MallId": 92
            }
          ],
          "Id": 270,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:39:34",
          "UpdateTime": "2021-12-04 17:39:34",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 379,
        "PeriodType": 1,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 87,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:23:07",
        "UpdateTime": "2021-12-01 10:23:07",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 379,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 485,
          "Delstatus": false,
          "AddTime": "2021-12-04 17:39:34",
          "UpdateTime": "2021-12-04 17:39:34",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 379,
      "Delstatus": false,
      "AddTime": "2021-12-04 17:39:34",
      "UpdateTime": "2021-12-04 17:40:23",
      "MallId": 92
    }
  },
  {
    templateId:15,
    title:'喜爱拼团非推手',
    description:'近30天成功发起拼团超过2次的客户，转化成为推手',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "喜爱拼团非推手运营",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 298,
          "Type": 3,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 298,
              "AutoMarketingTaskConfigDetailId": 182,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1177,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:25:07",
              "UpdateTime": "2021-12-01 10:25:07",
              "MallId": 92
            }
          ],
          "Id": 182,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:25:07",
          "UpdateTime": "2021-12-01 10:25:07",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 298,
          "Type": 402,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 1,
          "Num": 2,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 183,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:25:07",
          "UpdateTime": "2021-12-01 10:25:07",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 298,
        "PeriodType": 2,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 62,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:25:07",
        "UpdateTime": "2021-12-01 10:25:07",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 298,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 394,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:25:07",
          "UpdateTime": "2021-12-01 10:25:07",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 298,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:25:07",
      "UpdateTime": "2021-12-01 10:25:07",
      "MallId": 92
    },
  },
  {
    templateId:16,
    title:'喜爱助力非推手',
    description:'近30天成功发起助力超过2次的客户，转化成为推手',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "喜爱助力非推手转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 299,
          "Type": 3,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 299,
              "AutoMarketingTaskConfigDetailId": 184,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1178,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:25:57",
              "UpdateTime": "2021-12-01 10:25:57",
              "MallId": 92
            }
          ],
          "Id": 184,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:25:57",
          "UpdateTime": "2021-12-01 10:25:57",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 299,
          "Type": 403,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 1,
          "Num": 2,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 185,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:25:57",
          "UpdateTime": "2021-12-01 10:25:57",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 299,
        "PeriodType": 2,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 63,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:25:57",
        "UpdateTime": "2021-12-01 10:25:57",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 299,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 395,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:25:57",
          "UpdateTime": "2021-12-01 10:25:57",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 299,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:25:57",
      "UpdateTime": "2021-12-01 10:25:57",
      "MallId": 92
    },
  },
  {
    templateId:17,
    title:'高质量推手升级',
    description:'近30天累计收益高于商城所有推手收益平均值的客户，引导成为更高级别推手',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "高质量推手升级",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 301,
          "Type": 3,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 301,
              "AutoMarketingTaskConfigDetailId": 186,
              "RelationId": 9,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1179,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:27:07",
              "UpdateTime": "2021-12-01 10:27:07",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 301,
              "AutoMarketingTaskConfigDetailId": 186,
              "RelationId": 10,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1180,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:27:07",
              "UpdateTime": "2021-12-01 10:27:07",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 301,
              "AutoMarketingTaskConfigDetailId": 186,
              "RelationId": 11,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1181,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:27:07",
              "UpdateTime": "2021-12-01 10:27:07",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 301,
              "AutoMarketingTaskConfigDetailId": 186,
              "RelationId": 12,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1182,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:27:07",
              "UpdateTime": "2021-12-01 10:27:07",
              "MallId": 92
            }
          ],
          "Id": 186,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:27:07",
          "UpdateTime": "2021-12-01 10:27:07",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 301,
          "Type": 500,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 1,
          "Num": 1000,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 187,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:27:07",
          "UpdateTime": "2021-12-01 10:27:07",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 301,
        "PeriodType": 2,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 64,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:27:07",
        "UpdateTime": "2021-12-01 10:27:07",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 301,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 397,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:27:07",
          "UpdateTime": "2021-12-01 10:27:07",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 301,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:27:07",
      "UpdateTime": "2021-12-01 10:27:07",
      "MallId": 92
    },
  },
  {
    templateId:18,
    title:'消极推手激励',
    description:'成为推手后，有分享过商城页面，但是从来没有拿到过收益的客户',
    tag: '周期循环',
	  showCount: true,
    data:{
      "Name": "消极推手激励",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": false,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 303,
          "Type": 3,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 303,
              "AutoMarketingTaskConfigDetailId": 188,
              "RelationId": 9,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1183,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:31:38",
              "UpdateTime": "2021-12-01 10:31:38",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 303,
              "AutoMarketingTaskConfigDetailId": 188,
              "RelationId": 10,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1184,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:31:38",
              "UpdateTime": "2021-12-01 10:31:38",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 303,
              "AutoMarketingTaskConfigDetailId": 188,
              "RelationId": 11,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1185,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:31:38",
              "UpdateTime": "2021-12-01 10:31:38",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 303,
              "AutoMarketingTaskConfigDetailId": 188,
              "RelationId": 12,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1186,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:31:38",
              "UpdateTime": "2021-12-01 10:31:38",
              "MallId": 92
            },
            {
              "AutoMarketingTaskConfigId": 303,
              "AutoMarketingTaskConfigDetailId": 188,
              "RelationId": 17,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1187,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:31:38",
              "UpdateTime": "2021-12-01 10:31:38",
              "MallId": 92
            }
          ],
          "Id": 188,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:31:38",
          "UpdateTime": "2021-12-01 10:31:38",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 303,
          "Type": 6,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 189,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:31:38",
          "UpdateTime": "2021-12-01 10:31:38",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 303,
          "Type": 302,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 2,
          "Num": 1,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 303,
              "AutoMarketingTaskConfigDetailId": 190,
              "RelationId": 0,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1188,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:31:38",
              "UpdateTime": "2021-12-01 10:31:38",
              "MallId": 92
            }
          ],
          "Id": 190,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:31:38",
          "UpdateTime": "2021-12-01 10:31:38",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 303,
          "Type": 500,
          "TimeType": 1,
          "DynamicTimeType": 10,
          "ConditionType": 5,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 0,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [],
          "Id": 191,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:31:38",
          "UpdateTime": "2021-12-01 10:31:38",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 303,
        "PeriodType": 2,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 65,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:31:38",
        "UpdateTime": "2021-12-01 10:31:38",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 303,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 399,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:31:38",
          "UpdateTime": "2021-12-01 10:31:38",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 303,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:31:38",
      "UpdateTime": "2021-12-01 10:31:38",
      "MallId": 92
    },
  },
  //商品成交与复购 [19,20,21,22,23]
  {
    templateId:19,
    title:'浏览支付转化',
    description:'近7天浏览过某商品或某品牌或某分组下的商品，超出2次，但是没有成功支付的客户',
    tag: '周期循环',
	  showCount: false,
    data:{
      "Name": "商品成交-浏览支付转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 304,
          "Type": 301,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 2,
          "Num": 2,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 1,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 304,
              "AutoMarketingTaskConfigDetailId": 192,
              "RelationId": 468,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1189,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:34:47",
              "UpdateTime": "2021-12-01 10:34:47",
              "MallId": 92
            }
          ],
          "Id": 192,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:34:47",
          "UpdateTime": "2021-12-01 10:34:47",
          "MallId": 92
        },
        {
          "AutoMarketingTaskConfigId": 304,
          "Type": 15,
          "TimeType": 1,
          "DynamicTimeType": 8,
          "ConditionType": 5,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 1,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 304,
              "AutoMarketingTaskConfigDetailId": 193,
              "RelationId": 468,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1190,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:34:47",
              "UpdateTime": "2021-12-01 10:34:47",
              "MallId": 92
            }
          ],
          "Id": 193,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:34:47",
          "UpdateTime": "2021-12-01 10:34:47",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 304,
        "PeriodType": 1,
        "PeriodNum": 2,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 66,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:34:47",
        "UpdateTime": "2021-12-01 10:34:47",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 304,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 232,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:34:47",
          "UpdateTime": "2021-12-01 11:20:04",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 304,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 400,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:34:47",
          "UpdateTime": "2021-12-01 11:20:04",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 304,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:34:47",
      "UpdateTime": "2021-12-01 11:20:04",
      "MallId": 92
    },
  },
  {
    templateId:20,
    title:'搜索转化',
    description:'近7天搜索过某关键词，但是没有成功支付的客户',
    tag: '行为触发',
	  showCount: false,
    data:{
      "Name": "搜索转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 1,
      "PushRuleType": 3,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [],
      "AutoMarketingTaskConfigPushRuleEvent": {
        "AutoMarketingTaskConfigId": 306,
        "TimeType": 0,
        "Type": 15,
        "ConditionType": 2,
        "Num": 1,
        "MaxNum": 0,
        "ProductRangeType": 0,
        "PageRangeType": 0,
        "PushDelayDay": 1,
        "AutoMarketingTaskConfigPushRuleEventRelationIdList": [
          // {
          //   "AutoMarketingTaskConfigId": 306,
          //   "AutoMarketingTaskConfigPushRuleEventId": 104,
          //   "RelationId": 0,
          //   "RelationType": 0,
          //   "RelationValue": "关键词",
          //   "PagePath": "",
          //   "PageName": "",
          //   "ProductName": "",
          //   "ProductImgUrl": "",
          //   "ProductImgUrlComplete": "",
          //   "HeadUrl": "",
          //   "Id": 238,
          //   "Delstatus": false,
          //   "AddTime": "2021-12-01 10:38:43",
          //   "UpdateTime": "2021-12-01 10:38:43",
          //   "MallId": 92
          // }
        ],
        "Id": 104,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:38:43",
        "UpdateTime": "2021-12-01 10:38:43",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 306,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 234,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:38:43",
          "UpdateTime": "2021-12-01 10:38:43",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 306,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 402,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:38:43",
          "UpdateTime": "2021-12-01 10:38:43",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 306,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:38:43",
      "UpdateTime": "2021-12-01 10:38:43",
      "MallId": 92
    },
  },
  {
    templateId:21,
    title:'商品复购',
    description:'针对购买过某商品的客户，在一段时间后进行回访与复购转化',
    tag: '行为触发',
	  showCount: false,
    data:{
      "Name": "商品复购",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 1,
      "PushRuleType": 3,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [],
      "AutoMarketingTaskConfigPushRuleEvent": {
        "AutoMarketingTaskConfigId": 307,
        "TimeType": 0,
        "Type": 3,
        "ConditionType": 2,
        "Num": 1,
        "MaxNum": 0,
        "ProductRangeType": 1,
        "PageRangeType": 0,
        "PushDelayDay": 30,
        "AutoMarketingTaskConfigPushRuleEventRelationIdList": [
          {
            "AutoMarketingTaskConfigId": 307,
            "AutoMarketingTaskConfigPushRuleEventId": 105,
            "RelationId": 468,
            "RelationType": 0,
            "RelationValue": "分组3",
            "PagePath": "",
            "PageName": "",
            "ProductName": "",
            "ProductImgUrl": "",
            "ProductImgUrlComplete": "",
            "HeadUrl": "",
            "Id": 239,
            "Delstatus": false,
            "AddTime": "2021-12-01 10:39:22",
            "UpdateTime": "2021-12-01 10:39:22",
            "MallId": 92
          }
        ],
        "Id": 105,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:39:22",
        "UpdateTime": "2021-12-01 10:39:22",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 307,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 235,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:39:22",
          "UpdateTime": "2021-12-01 10:39:22",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 307,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 403,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:39:22",
          "UpdateTime": "2021-12-01 10:39:22",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 307,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:39:22",
      "UpdateTime": "2021-12-01 10:39:22",
      "MallId": 92
    },
  },
  {
    templateId:22,
    title:'购物车商品转化',
    description:'客户将某商品加入到购物车里超出一段时间还未购买，对这些客户进行支付转化',
    tag: '周期循环',
	  showCount: false,
    data:{
      "Name": "购物车商品转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-01-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 308,
          "Type": 201,
          "TimeType": 0,
          "DynamicTimeType": 0,
          "ConditionType": 0,
          "Num": 0,
          "MaxNum": 0,
          "OverDayNum": 7,
          "ProductRangeType": 1,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 308,
              "AutoMarketingTaskConfigDetailId": 196,
              "RelationId": 468,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1192,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:40:19",
              "UpdateTime": "2021-12-01 10:40:19",
              "MallId": 92
            }
          ],
          "Id": 196,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:40:19",
          "UpdateTime": "2021-12-01 10:40:19",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 308,
        "PeriodType": 1,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 68,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:40:19",
        "UpdateTime": "2021-12-01 10:40:19",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 308,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 236,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:40:19",
          "UpdateTime": "2021-12-01 10:40:19",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 308,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 404,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:40:19",
          "UpdateTime": "2021-12-01 10:40:19",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 308,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:40:19",
      "UpdateTime": "2021-12-01 10:40:19",
      "MallId": 92
    },
  },
  {
    templateId:23,
    title:'相关商品转化',
    description:'对经常购买某商品的客户，推荐相关商品，共同满足需求（比如经常购买某品牌爽肤水，推荐购买同品牌乳液或面霜）',
    tag: '周期循环',
	  showCount: false,
    data:{
      "Name": "相关商品转化",
      "State": 1,
      "StartTime": "2021-12-01 00:00:00",
      "EndTime": "2022-02-01 00:00:00",
      "IsOpenTransform": false,
      "TransformWithinDay": 7,
      "CompleteTargetType": 1,
      "TargetMemberType": 6,
      "PushRuleType": 2,
      "IsOpenGiftCoupon": true,
      "GiftCouponWay": 1,
      "IsOpenGiftWheelSurf": false,
      "Rounds": 0,
      "AutoMarketingTaskConfigDetailList": [
        {
          "AutoMarketingTaskConfigId": 309,
          "Type": 15,
          "TimeType": 1,
          "DynamicTimeType": 6,
          "ConditionType": 2,
          "Num": 1,
          "MaxNum": 0,
          "OverDayNum": 0,
          "ProductRangeType": 1,
          "PageRangeType": 0,
          "ActivityBehaviorType": 0,
          "IsMatchAll": false,
          "RuleRemark": "",
          "AutoMarketingTaskConfigDetailRelationIdList": [
            {
              "AutoMarketingTaskConfigId": 309,
              "AutoMarketingTaskConfigDetailId": 197,
              "RelationId": 468,
              "RelationValue": "",
              "PagePath": "",
              "PageName": "",
              "ProductImgUrlComplete": "",
              "Id": 1193,
              "Delstatus": false,
              "AddTime": "2021-12-01 10:48:00",
              "UpdateTime": "2021-12-01 10:48:00",
              "MallId": 92
            }
          ],
          "Id": 197,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:48:00",
          "UpdateTime": "2021-12-01 10:48:00",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleLoop": {
        "AutoMarketingTaskConfigId": 309,
        "PeriodType": 2,
        "PeriodNum": 1,
        "PeriodTimeHour": 20,
        "PeriodTimeMinute": 0,
        "PushCount": 0,
        "Id": 69,
        "Delstatus": false,
        "AddTime": "2021-12-01 10:48:00",
        "UpdateTime": "2021-12-01 10:48:00",
        "MallId": 92
      },
      "AutoMarketingTaskConfigGiftList": [
        {
          "AutoMarketingTaskConfigId": 309,
          "Type": 1,
          "RelationId": 573,
          "GiftCount": 1,
          "CouponInfo": {
            "CouponName": "抽奖优惠券收回",
            "UseType": 0,
            "UseTypeValue": "满减券",
            "FullMoney": 0,
            "MinMoney": 2,
            "MaxMoney": 0,
            "Discount": 0,
            "CouponContent": "无门槛,减2.00元",
            "ActivityRangeType": 0,
            "ActivityRangeTypeValue": "全部商品",
            "SendCount": 4,
            "AllCount": 1332,
            "LeaveCount": 1328,
            "IsOpen": true,
            "State": ""
          },
          "WheelSurfInfo": {
            "Name": ""
          },
          "Id": 237,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:48:00",
          "UpdateTime": "2021-12-01 11:22:10",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigMessageList": [
        {
          "AutoMarketingTaskConfigId": 309,
          "Type": 2,
          "HeadSign": "【新测试商户-推手模式】",
          "Content": "这是一条示例短信，您可以点击下方插入商城链接，在短信里插入商城首页或自定义页面或商品等页面链接",
          "Tail": "回复TD退订",
          "QyWeixinAttachmentInfoList": [],
          "SMSContentAttachInfo": {
            "MallLinkUrl": "",
            "EmployeeActiveCodeUrl": ""
          },
          "Id": 405,
          "Delstatus": false,
          "AddTime": "2021-12-01 10:48:00",
          "UpdateTime": "2021-12-01 11:22:10",
          "MallId": 92
        }
      ],
      "AutoMarketingTaskConfigPushRuleEvent":{},
      "AutoMarketingTaskConfigPushRuleOnce":{},
      "Id": 309,
      "Delstatus": false,
      "AddTime": "2021-12-01 10:48:00",
      "UpdateTime": "2021-12-01 11:22:10",
      "MallId": 92
    },
  },
]